import { createStore } from "vuex";
import axios from "axios";
import { darkModeKey } from "@/config.js";
import { auth } from "./auth.module";
import { invoices } from "./invoices.module";
import { subscriptions } from "./subscription.module";

export default createStore({
  state: {
    /* User */
    userName: null,
    userEmail: null,
    userAvatar: null,

    /* fullScreen - fullscreen form layout (e.g. login page) */
    isFullScreen: false,

    /* Aside */
    isAsideMobileExpanded: false,
    isAsideLgActive: false,

    /* Dark mode */
    darkMode: false,

    /* Field focus with ctrl+k (to register only once) */
    isFieldFocusRegistered: false,

    /* Sample data (commonly used) */
    clients: [],
    history: [],

    displayText: "",
    displayHeader: "",
    displayType: "",
  },
  mutations: {
    /* A fit-them-all commit */
    basic(state, payload) {
      state[payload.key] = payload.value;
    },

    /* User */
    user(state, payload) {
      if (payload.name) {
        state.userName = payload.name;
      }
      if (payload.email) {
        state.userEmail = payload.email;
      }
      if (payload.avatar) {
        state.userAvatar = payload.avatar;
      }
    },
  },
  actions: {
    setDisplayMessage({ commit, state }, payload = null) {
      commit("basic", {
        key: "displayText",
        value: payload.displayText,
      });

      commit("basic", {
        key: "displayType",
        value: payload.displayType,
      });

      commit("basic", {
        key: "displayHeader",
        value: payload.displayHeader,
      });
    },

    asideMobileToggle({ commit, state }, payload = null) {
      const isShow = payload !== null ? payload : !state.isAsideMobileExpanded;

      document
        .getElementById("app")
        .classList[isShow ? "add" : "remove"]("ml-60", "lg:ml-0");

      document.documentElement.classList[isShow ? "add" : "remove"](
        "m-clipped"
      );

      commit("basic", {
        key: "isAsideMobileExpanded",
        value: isShow,
      });
    },

    asideLgToggle({ commit, state }, payload = null) {
      commit("basic", {
        key: "isAsideLgActive",
        value: payload !== null ? payload : !state.isAsideLgActive,
      });
    },

    fullScreenToggle({ commit, state }, value) {
      commit("basic", { key: "isFullScreen", value });

      document.documentElement.classList[value ? "add" : "remove"](
        "full-screen"
      );
    },

    darkMode({ commit, state }) {
      const value = !state.darkMode;

      document.documentElement.classList[value ? "add" : "remove"]("dark");

      localStorage.setItem(darkModeKey, value ? "1" : "0");

      commit("basic", {
        key: "darkMode",
        value,
      });
    },

    fetch({ commit }, payload) {
      axios
        .get(`data-sources/${payload}.json`)
        .then((r) => {
          if (r.data && r.data.data) {
            commit("basic", {
              key: payload,
              value: r.data.data,
            });
          }
        })
        .catch((error) => {
          alert(error.message);
        });
    },
  },
  modules: {
    auth,
    invoices,
    subscriptions,
  },
});
