import subscriptionService from "@/services/subscription.service";

const initialState = {
  subscriptions: [],
  loading: false,
};

export const subscriptions = {
  namespaced: true,
  state: initialState,
  actions: {
    async getSubscriptions({ commit }) {
      commit('setLoading', true);
      try {
        const response = await subscriptionService.getSubscriptions();
        commit('setSubscriptions', response.data);
      } finally {
        commit('setLoading', false);
      }
    },
  },
  mutations: {
    setSubscriptions(state, subscriptions) {
      state.subscriptions = subscriptions;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
  },
};