const API_URL = window.location.href.includes("localhost") ? "http://localhost:8000/api" : "https://backend.scrappey.com/api"
// const STRIPE_URL = window.location.href.includes("localhost") ? "http://localhost:8000/api" : "https://payments-stripe.scrappey.com"
const STRIPE_URL = window.location.href.includes("localhost") ? "http://localhost:4242" : "https://scrappey-stripe-backend-uuvarjp72q-ew.a.run.app"

const SUBSCRIPTION_SUCCESS = window.location.href.includes("localhost") 
  ? "http://localhost:8080/#/dashboard"
  : "https://app.scrappey.com/#/dashboard"

const SUBSCRIPTION_CANCEL = window.location.href.includes("localhost")
  ? "http://localhost:8080/#/replenish"
  : "https://app.scrappey.com/#/replenish"

const GLOBAL_URLS = {
  API_URL, 
  STRIPE_URL,
  SUBSCRIPTION_SUCCESS,
  SUBSCRIPTION_CANCEL
}

export default  GLOBAL_URLS 