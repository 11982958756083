import axios from "axios";

const WEBHOOK_URL = window.location.href.includes("localhost")
  ? "http://127.0.0.1:3006"
  : `https://scrappey-webhook-uuvarjp72q-ew.a.run.app`;

const instance = axios.create({
    baseURL: WEBHOOK_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

class ZapierService {

    /**
     * 
     * @param {*} user 
     * @returns 
     */
    create(user) {
        return instance.post(`/webhook`, {
            type: 'register',
            data: {
                email: user.email
            }
        })
    }


    /**
     * 
     * @param {*} user 
     * @returns 
     */
    updateFirstOrderCreation(user) {
        return instance.post(`/webhook`, {
            type: 'update-first-order',
            data: {
                email: user.email
            }
        })
    }

    /**
     * 
     * @param {*} user 
     * @returns 
     */
    verifyEmail(user) {
        return instance.post(`/webhook`, {
            type: 'verify-email',
            data: {
                user_id: user.id
            }
        })
    }
}

export default new ZapierService();
