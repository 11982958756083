import invoiceService from "@/services/invoice.service";

const initialState = {
  invoices: undefined,
};

export const invoices = {
  namespaced: true,
  state: initialState,
  actions: {
    getInvoices({ commit }, payload = null) {
      invoiceService.getInvoices().then((value) => {
        commit("getInvoices", value.data);
      });
    },
  },
  mutations: {
    getInvoices(state, item) {
      state.invoices = item;
    },
  },
};
