import GLOBAL_URLS from "@/globals/api";
import authHeader from "./auth-header";
import axiosInstance from "./api";

class InvoiceService {
  getInvoices() {
    return axiosInstance
      .get(`${GLOBAL_URLS.API_URL}/invoices/get`, {
        headers: {
          "Content-Type": "application/json",
          ...authHeader()
        },
      })
  }
}

export default new InvoiceService();
