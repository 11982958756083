import axios from "axios";
import API_URL from "@/globals/api";
import authHeader from "./auth-header";

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    ...authHeader(),
  },
  // withCredentials: true
});

export default instance;
