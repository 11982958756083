import {
  mdiAccountCircle,
  mdiDesktopMac,
  mdiGithub,
  mdiLock,
  mdiAlertCircle,
  mdiMonitorShimmer,
  mdiSquareEditOutline,
  mdiTable,
  mdiViewList,
  mdiTelevisionGuide,
  mdiResponsive,
  mdiFaceManProfile,
  mdiBorderColor,
  mdiChatOutline,
  mdiChartTimelineVariantShimmer,
  mdiHelpCircleOutline,
  mdiCart,
  mdiFormatListBulleted,
  mdiAccountGroup,
  mdiMessageText,
  mdiDomain,
  mdiViewDashboardOutline,
  mdiContentCopy,
  mdiCurrencyUsd,
  mdiShimmer,
  mdiListStatus,
  mdiWalk,
  mdiBookshelf,
  mdiEmailFast,
  mdiHammer,
  mdiFeatureSearch,
  mdiCommentQuote,
  mdiFirework,
  mdiPencil,
  mdiStore24Hour,
  mdiBookmarkOutline,
  mdiFlower,
  mdiWrench,
  mdiCreditCardCheckOutline
} from "@mdi/js";
import { mdiPlusCircleOutline } from '@mdi/js';

export default [
  "Verify",
  [{
    to: "/verify",
    label: "Verify e-mail",
    icon: mdiEmailFast,
    updateMark: true,
  }],
  "General",
  [
    {
      to: "/",
      label: "Dashboard",
      icon: mdiViewDashboardOutline,
      updateMark: true,
    },
    {
      to: "/pricing",
      label: "Functionality",
      icon: mdiWalk,
      updateMark: true,
    },
    {
      to: "/profilev2",
      label: "Profile",
      icon: mdiFaceManProfile,
      updateMark: true,
    },
    {
      to: "/settings",
      label: "Settings",
      icon: mdiWrench,
      updateMark: true,
    }
  ],
  "Business",
  [
    {
      to: "/company",
      label: "Business",
      icon: mdiDomain,
      updateMark: true,
    },
  ],
  "Builder",
  [
    {
      to: "/builder",
      label: "Request Builder",
      icon: mdiHammer,
      updateMark: true,
    },
    {
      to: "/scraper",
      label: "Directory",
      icon: mdiBookmarkOutline,
      updateMark: true,
    },
    {
      href: "https://wiki.scrappey.com/",
      target: "_blank",
      label: "Wiki",
      icon: mdiBookshelf,
      updateMark: true,
    },
    {
      href: "https://status.scrappey.com/",
      target: "_blank",
      label: "Server Status",
      icon: mdiListStatus,
      updateMark: true,
    },
    // {
    //   href: "https://scrappey.getrewardful.com/signup",
    //   label: "Affliate Program",
    //   target: "_blank",
    //   icon: mdiPencil,
    //   updateMark: true,
    // },
  ],
  "Payments",
  [
    {
      to: "/replenish",
      label: "Replenish",
      icon: mdiCurrencyUsd,
      updateMark: true,
    },
    {
      to: "/subscriptions", // Add this
      label: "Subscriptions",
      icon: mdiCreditCardCheckOutline, // Import this icon
      updateMark: true,
    },
    {
      to: "/invoices",
      label: "View payments",
      icon: mdiFormatListBulleted,
      updateMark: true,
    },
  ],
  "Social",
  [
    {
      to: "/features",
      label: "Discord",
      icon: mdiChatOutline,
      updateMark: true,
    },
    {
      href: "https://scrappey.canny.io/features-requests",
      label: "Feature Request",
      target: "_blank",
      icon: mdiCommentQuote,
      updateMark: true,
    },
  ],
  // "Partners",
  // [
  //   {
  //     href: "https://bumper-oo.com/",
  //     label: "Auto Bumper",
  //     target: "_blank",
  //     icon: mdiStore24Hour,
  //     updateMark: true,
  //   },
  // ]
  // [
  //   {
  //     to: '/tables',
  //     label: 'Tables',
  //     icon: mdiTable,
  //     updateMark: true
  //   },
  //   {
  //     to: '/forms',
  //     label: 'Forms',
  //     icon: mdiSquareEditOutline
  //   },
  //   {
  //     to: '/ui',
  //     label: 'UI',
  //     icon: mdiTelevisionGuide
  //   },
  //   {
  //     to: '/responsive',
  //     label: 'Responsive',
  //     icon: mdiResponsive
  //   },
  //   {
  //     to: '/profile',
  //     label: 'Profile',
  //     icon: mdiAccountCircle
  //   },
  //   {
  //     to: '/login',
  //     label: 'Login',
  //     icon: mdiLock
  //   },
  //   {
  //     to: '/error',
  //     label: 'Error',
  //     icon: mdiAlertCircle
  //   },
  //   {
  //     label: 'Submenus',
  //     subLabel: 'Submenus Example',
  //     icon: mdiViewList,
  //     menu: [
  //       {
  //         label: 'Sub-item One'
  //       },
  //       {
  //         label: 'Sub-item Two'
  //       }
  //     ]
  //   }
  // ],
  // 'About',
  // [

  // ],
];
