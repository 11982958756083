<script setup>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import MainSection from "@/components/MainSection.vue";
import CardComponent from "@/components/CardComponent.vue";
import TitleBar from "@/components/TitleBar.vue";
import JbButton from "@/components/JbButton.vue";
import JbButtons from "@/components/JbButtons.vue";
import Spinner from "@/components/Spinner.vue";
import { mdiRefresh } from "@mdi/js";
import subscriptionService from "@/services/subscription.service";
import { useNotificationStore } from '@dafcoe/vue-notification';
import SubscriptionPlans from "@/views/SubscriptionPlans.vue";

const titleStack = ref(["Payments", "Subscriptions"]);
const store = useStore();
const { setNotification } = useNotificationStore();

const userId = computed(() => store.state.auth.user.user.id);
const subscriptions = computed(() => store.state.subscriptions.subscriptions);
const loading = computed(() => store.state.subscriptions.loading);

const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
};

const getStatusColor = (status) => {
    switch (status) {
        case 'active':
            return 'text-green-600';
        case 'trialing':
            return 'text-yellow-600';
        case 'canceled':
        case 'incomplete':
            return 'text-red-600';
        default:
            return 'text-gray-600';
    }
};

const manageSubscription = async () => {
    try {
        const response = await subscriptionService.createPortalSession(userId.value);
        window.location.href = response.data.url;
    } catch (error) {
        // Try to redirect to Stripe billing portal as fallback
        window.location.href = 'https://billing.stripe.com/p/login/8wM9Ci2v9gf31QkeUU';
        
        setNotification({
            duration: 3000,
            type: "warning",
            message: "Redirecting to Stripe billing portal..."
        });
    }
};

const refreshSubscriptions = () => {
    store.dispatch("subscriptions/getSubscriptions");
};

onMounted(() => {
    store.dispatch("subscriptions/getSubscriptions");
});
</script>

<template>


    <main-section>
        <card-component>
            <title-bar :title-stack="['Payments', 'Subscriptions']" />
            <subscription-plans />
        </card-component>
    </main-section>

    <main-section>
        <card-component class="mb-6">
            <title-bar :title-stack="['Payments', 'Subscriptions', 'Overview']" />
            <div class="flex items-center justify-between">
                <h1 class="text-lg font-semibold">Subscription Overview</h1>
                <div class="flex gap-2">
                    <jb-buttons type="justify-start lg:justify-end" no-wrap>
                        <jb-button :icon="mdiRefresh" color="info" :small="true" @click="refreshSubscriptions"
                            :disabled="loading" />
                        <jb-button label="Manage in Stripe" color="info" :small="true" @click="manageSubscription"
                            :disabled="loading" />
                    </jb-buttons>
                </div>
            </div>

            <div v-if="loading" class="flex justify-center py-8">
                <!-- add spinner -->
                 
            </div>

            <div v-else-if="subscriptions.length === 0" class="text-center py-8">
                <p class="text-gray-500">No subscriptions found</p>
                <!-- <router-link to="/replenish" class="text-blue-600 hover:text-blue-800 mt-2 inline-block">
                    View available plans
                </router-link> -->
            </div>

            <div v-else class="overflow-x-auto">
                <table class="w-full">
                    <thead>
                        <tr>
                            <th>Subscription ID</th>
                            <th>Status</th>
                            <th>Amount</th>
                            <th>Current Period End</th>
                            <th>Created</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="sub in subscriptions" :key="sub.id">
                            <td data-label="ID">{{ sub.stripe_subscription_id }}</td>
                            <td data-label="Status">
                                <span :class="getStatusColor(sub.status)">
                                    {{ sub.status.charAt(0).toUpperCase() + sub.status.slice(1) }}
                                </span>
                            </td>
                            <td data-label="Amount">€{{ sub.plan_amount }}/month</td>
                            <td data-label="Period End">{{ formatDate(sub.current_period_end) }}</td>
                            <td data-label="Created">{{ formatDate(sub.created_at) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </card-component>
    </main-section>
</template>